import { getImageData } from "../../../seo.data";

// For images, you can create a reusable component
const OptimizedImage = ({ imageKey, className }) => {
  const imageData = getImageData(imageKey);

  return (
    <img
      src={imageData.src}
      alt={imageData.alt}
      title={imageData.title}
      className={className}
    />
  );
};

export default OptimizedImage;
