import React, { Fragment } from "react";
import "./services.css";
import AboutSmall from "../../assets/images/theme-2/AboutSmall.svg";
import Aboutbg from "../../assets/images/theme-2/about-img.jpg";
import Header from "../../componet/Header";
import { servicesData } from "./servicesdata";
import { Link } from "react-router-dom";
import Footer from "../../componet/Footer";
import ZohoChatWidget from "../../componet/widgets/ZohoChatWidget";
import ZohoDeskWidget from "../../componet/widgets/ZohoDeskWidget";
import useSEO from "../../hooks/useSEO";

const Services = () => {
  const { pageData } = useSEO("/services");
  return (
    <Fragment>
      <Header />
      <div className="about-bg">
        <img className="d-sm-block d-md-none" src={AboutSmall} />
        <img className="d-sm-none d-md-block d-none" src={Aboutbg} />
      </div>

      <section className="services-container">
        <Link to="https://chat.remotephysios.com" className="btn">
          Understand your condition
        </Link>
        {servicesData.map((service, index) => (
          <div
            key={service.id}
            className={`service-card ${
              index % 2 === 0 ? "left-image" : "right-image"
            }`}
          >
            <div
              className="service-image"
              style={{ backgroundImage: `url(${service.imageLink})` }}
            ></div>
            <div className="service-content">
              <h2 className="service-title">{service.name}</h2>
              <ul className="service-list">
                {service.bulletPoints.map((point) => (
                  <li key={point.id} className="bullet-item">
                    <span class="bullet"></span>
                    <span class="service"> {point.value}</span>
                  </li>
                ))}
              </ul>
              <Link
                to={"https://payments.cashfree.com/forms/remotephysios"}
                className="book-now"
              >
                Book Now
              </Link>
            </div>
          </div>
        ))}
      </section>
      <Footer />
    </Fragment>
  );
};

export default Services;
