import AboutSmall from "../../assets/images/theme-2/AboutSmall.svg";
import Aboutbg from "../../assets/images/theme-2/about-img.jpg";
import Header from "../../componet/Header";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./HighlightReader.css";
import { baseUrl } from "../Blogs/BlogList";

const HighlightReader = () => {
  const { id } = useParams();
  const [highlight, setHighlight] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHighlight = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/rp/highlights/${id}`);
        setHighlight(response.data.data);
        setError(null);
      } catch (error) {
        setError("Failed to load the highlight. Please try again later.");
        console.error("Error fetching highlight:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchHighlight();
  }, [id]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading the highlight...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
        <button onClick={() => window.location.reload()} className="retry-btn">
          Retry
        </button>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="about-bg">
        <img className="d-sm-block d-md-none" src={AboutSmall} loading="lazy" />
        <img
          className="d-sm-none d-md-block d-none"
          src={Aboutbg}
          loading="lazy"
        />
      </div>
      <div className="highlight-reader-container">
        {/* Back Button */}
        <Link to="/highlights" className="back-button">
          ← Back
        </Link>
        <div className="highlight-reader-image-wrapper">
          <img
            src={highlight.featureImageUrl}
            alt={`Feature for ${highlight.title}`}
            className="highlight-reader-image"
            loading="lazy"
          />
        </div>
        <h1 className="highlight-reader-title">{highlight.title}</h1>
        <div className="highlight-reader-meta">
          <p className="highlight-reader-date">
            {new Date(highlight.publishedDate).toLocaleDateString()}
          </p>
        </div>
        <div
          className="highlight-reader-content"
          dangerouslySetInnerHTML={{ __html: highlight.content }}
        ></div>
        {/* All Highlights Button */}
        <div className="all-highlights-container">
          <Link to="/highlights" className="all-highlights-button">
            View All Highlights
          </Link>
        </div>
      </div>
    </>
  );
};

export default HighlightReader;
