import LogoWhite from "./assets/images/theme-1/logo-w.png";

export const seoConfig = {
  // Global default settings
  default: {
    siteName: "Remote Physios",
    separator: "|",
    baseUrl: "https://remotephysios.com",
  },

  // Page-specific SEO configurations
  pages: {
    home: {
      path: "/",
      title: "Affordable Remote Physiotherapy Services | Expert Care at Home",
      description:
        "Receive expert physiotherapy care from the comfort of your home. Our remote physiotherapy services provide personalized treatment plans to aid recovery, improve mobility, and reduce pain. Accessible and affordable care, anytime, anywhere.",
      h1: "Personalized Physiotherapy, Delivered to Your Doorstep",
      content: {
        mainPitch:
          "At Remote Physios, we know how difficult it can be to prioritize your health amidst a busy schedule or mobility challenges. That's why we bring physiotherapy at home, making expert care accessible without the hassle of travel. Through advanced teleconsultations and IoT-enabled equipment, we provide personalized, evidence-based care tailored to your needs.",
        services:
          "Our services are ideal for post-surgery recovery, chronic pain management, neurological rehabilitation, and elderly care. With the support of the best physiotherapists, we also specialize in neurotherapy to help restore mobility and improve quality of life. Whether you're recovering from an injury or managing a long-term condition, Remote Physios ensures you get the care you deserve in the comfort of your home.",
      },
    },

    services: {
      path: "/services",
      title: "Online Physiotherapy Services | Tailored Care by Remote Physios",
      description:
        "Discover comprehensive online physiotherapy services at Remote Physios. From chronic pain management to post-surgery rehab, get expert care tailored to your needs. Start your recovery journey now!",
      h1: "Experience Advanced Physiotherapy at Home with Expert Care: Convenient, Personalized, and Effective Solutions for Your Recovery",
    },

    highlights: {
      path: "/highlights",
      title: "Best Physiotherapist | Expert Care for Pain Relief & Recovery",
      description:
        "Get more from home physiotherapy with expert care tailored to your needs. Experience convenient, personalized treatment for pain relief and mobility improvement at your doorstep.",
    },

    blogs: {
      path: "/blogs",
      title: "Home Physiotherapy Services | Get More Personalized Care at Home",
      description:
        "Experience more convenience with expert home physiotherapy services. Get personalized care for pain relief, recovery, and mobility improvement in the comfort of your home.",
    },

    about: {
      path: "/about",
      title:
        "Physiotherapy at Home | Professional In-Home Physiotherapy Services",
      description:
        "Get expert physiotherapy at home with Physios Remote. Enjoy personalized care, faster recovery, and improved mobility in the comfort of your home. Book your session today!",
      content: {
        main: "At Remote Physios, we redefine the way physiotherapy is delivered by bringing physiotherapy at home, tailored to your needs. Our innovative approach combines expert care from the best physiotherapists with cutting-edge IoT-enabled devices, ensuring you receive high-quality, personalized treatments without stepping out of your home. Whether it's neurotherapy, chronic pain relief, post-surgery rehabilitation, or elderly care, we've got you covered.",
        team: "Designed by a team of seasoned physiotherapists and healthcare technology specialists, Remote Physios is built to suit modern-day lifestyles. From busy professionals managing back pain to seniors requiring regular physiotherapy or individuals recovering from injuries, we craft custom treatment plans that adapt to your progress and goals.",
        technology:
          "Our platform offers seamless video consultations, real-time monitoring, and data-driven insights, ensuring every session is effective and outcome-focused. By merging advanced technology with professional expertise, we empower you to take charge of your health, recover faster, and enhance your physical well-being—all from the comfort and safety of your home.",
        closing:
          "Choose Remote Physios for a smarter, more convenient path to recovery. Your journey to a healthier, pain-free life starts here!",
      },
    },
  },

  // Image configurations
  images: {
    logo: {
      src: LogoWhite,
      title: "Remotely Physiotherapy - Expert Care at Home Logo",
      alt: "Remotely Physiotherapy Logo - Professional and Convenient Care",
    },
    whyRemotePhysios: {
      src: "why_remote_physios.8c27421ce4446cc6b588.png",
      title: "Home Delivery of Prescriptions - Fast and Reliable Service",
      alt: "Medicine Home Delivery - Convenient and Reliable Prescription Service",
    },
    process: {
      src: "product01.8c5fa82319a70b631c90.png",
      title: "How Remote Physiotherapy Works - Easy Online Therapy Process",
      alt: "Remote Physiotherapy Process - Step-by-Step Online Therapy Guidance",
    },
    testimonials: {
      src: "Customer-feedback.9c8dfdb0be7fc594f37e.png",
      title: "Customer Testimonials - Hear What Patients Say About Our Care",
      alt: "Patient Testimonial - Positive Feedback on Physiotherapy Services",
    },
    services: {
      src: "Services-img.c2af453321203fbab4b3.png",
      title:
        "Explore Our Physiotherapy Services - Tailored Treatments for Your Needs",
      alt: "Physiotherapy Services - Comprehensive Care for Pain Relief and Recovery",
    },
    about: {
      src: "About-img.20e86783a718205f05f1.jpg",
      title: "Remote Physiotherapy Services - Expert Care from Anywhere",
      alt: "Expert physiotherapist conducting an online consultation for remote care",
    },
    aboutSmall: {
      src: "AboutSmall.5f652c1ddfb13fe58664eaf56901c06f.svg",
      title: "Best Physiotherapist - Expert Care for Pain Relief & Recovery",
      alt: "Best physiotherapist providing online consultation for pain relief and recovery",
    },
    highlights: {
      src: "1737355686128_Untitled design (1).png",
      title: "More Convenience with Home Physiotherapy Services",
      alt: "Home physiotherapy session with a skilled physiotherapist providing care",
    },
  },

  // Schema.org structured data
  schema: {
    organization: {
      "@type": "Organization",
      name: "Remote Physios",
      description: "Expert physiotherapy care delivered to your home",
      url: "https://remotephysios.com",
      sameAs: [
        // Add social media URLs here
      ],
    },
    service: {
      "@type": "MedicalTherapy",
      name: "Remote Physiotherapy Services",
      description: "Professional physiotherapy services delivered at home",
      medicineSystem: "Physiotherapy",
      relevantSpecialty: "Physical Therapy",
    },
  },
};

export const getSeoData = (pagePath) => {
  return (
    Object.values(seoConfig.pages).find((page) => page.path === pagePath) ||
    seoConfig.pages.home
  );
};

export const getImageData = (imageKey) => {
  return seoConfig.images[imageKey];
};

export default seoConfig;
