import { useEffect } from "react";

// Import the SEO configuration
import seoConfig, { getSeoData, getImageData } from "../seo.data";

// Enhance default SEO config with site-wide defaults
const defaultSEOConfig = {
  // Basic meta tags
  title: seoConfig.default.siteName,
  titleTemplate: `%s ${seoConfig.default.separator} ${seoConfig.default.siteName}`,
  description: "",
  keywords:
    "physiotherapy, remote physiotherapy, online physiotherapy, physical therapy",
  canonical: "",

  // Open Graph
  "og:title": "",
  "og:description": "",
  "og:type": "website",
  "og:url": "",
  "og:image": "",
  "og:site_name": seoConfig.default.siteName,
  "og:locale": "en_US",

  // Twitter Cards
  "twitter:card": "summary_large_image",
  "twitter:title": "",
  "twitter:description": "",
  "twitter:image": "",

  // Additional SEO tags
  robots: "index, follow",
  googlebot: "index, follow",
  viewport: "width=device-width, initial-scale=1",
  "format-detection": "telephone=no",
  "theme-color": "#000000",
};

const useSEO = (pagePath, customConfig = {}) => {
  // Get page-specific SEO data
  const pageData = getSeoData(pagePath);

  const updateMetaTag = (name, content) => {
    if (!content) return;

    let element =
      document.querySelector(`meta[name="${name}"]`) ||
      document.querySelector(`meta[property="${name}"]`);

    if (!element) {
      element = document.createElement("meta");
      if (name.startsWith("og:") || name.startsWith("twitter:")) {
        element.setAttribute("property", name);
      } else {
        element.setAttribute("name", name);
      }
      document.head.appendChild(element);
    }

    element.setAttribute("content", content);
  };

  const updateTitle = (title, template = defaultSEOConfig.titleTemplate) => {
    const formattedTitle = template.replace("%s", title);
    document.title = formattedTitle;

    // Update OG and Twitter titles
    updateMetaTag("og:title", formattedTitle);
    updateMetaTag("twitter:title", formattedTitle);
  };

  const updateCanonical = (url) => {
    let canonical = document.querySelector('link[rel="canonical"]');
    if (!canonical) {
      canonical = document.createElement("link");
      canonical.setAttribute("rel", "canonical");
      document.head.appendChild(canonical);
    }
    canonical.setAttribute("href", url);
  };

  const updateSchema = () => {
    const schema = {
      "@context": "https://schema.org",
      ...seoConfig.schema.organization,
      address: {
        "@type": "PostalAddress",
        addressCountry: "IN",
      },
    };

    if (pagePath === "/services") {
      schema.hasOfferCatalog = {
        "@type": "OfferCatalog",
        name: "Physiotherapy Services",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              ...seoConfig.schema.service,
            },
          },
        ],
      };
    }

    let scriptElement = document.querySelector(
      'script[type="application/ld+json"]'
    );
    if (!scriptElement) {
      scriptElement = document.createElement("script");
      scriptElement.setAttribute("type", "application/ld+json");
      document.head.appendChild(scriptElement);
    }
    scriptElement.textContent = JSON.stringify(schema);
  };

  useEffect(() => {
    // Merge configurations
    const finalConfig = {
      ...defaultSEOConfig,
      ...pageData,
      ...customConfig,
      canonical: `${seoConfig.default.baseUrl}${pagePath}`,
    };

    // Update title
    updateTitle(finalConfig.title);

    // Update canonical URL
    updateCanonical(finalConfig.canonical);

    // Update meta description
    if (finalConfig.description) {
      updateMetaTag("description", finalConfig.description);
      updateMetaTag("og:description", finalConfig.description);
      updateMetaTag("twitter:description", finalConfig.description);
    }

    // Update all other meta tags
    Object.entries(finalConfig).forEach(([name, content]) => {
      if (!["title", "titleTemplate", "canonical", "content"].includes(name)) {
        updateMetaTag(name, content);
      }
    });

    // Update schema markup
    updateSchema();

    // Cleanup function
    return () => {
      // Remove schema markup
      const schemaScript = document.querySelector(
        'script[type="application/ld+json"]'
      );
      if (schemaScript) {
        schemaScript.remove();
      }
    };
  }, [pagePath, customConfig]);

  // Return page data and utility functions
  return {
    pageData,
    updateMetaTag,
    updateTitle,
    updateCanonical,
  };
};

export default useSEO;
