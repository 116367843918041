import AboutSmall from "../../assets/images/theme-2/AboutSmall.svg";
import Aboutbg from "../../assets/images/theme-2/about-img.jpg";
import Header from "../../componet/Header";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./HighlightList.css";
import Footer from "../../componet/Footer";
import useSEO from "../../hooks/useSEO";
import { baseUrl } from "../Blogs/BlogList";

const HighlightList = () => {
  const { pageData } = useSEO("/highlights");
  const [highlights, setHighlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHighlights = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/rp/highlights/all`);
        setHighlights(response.data.data);
      } catch (error) {
        setError("Failed to fetch highlights. Please try again later.");
        console.error("Error fetching highlights:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchHighlights();
  }, []);

  const openHighlight = (id) => {
    navigate(`/highlights/${id}`);
  };

  const getExcerpt = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    return tempDiv.textContent.slice(0, 100) + "...";
  };

  return (
    <>
      <Header />
      <div className="about-bg">
        <img className="d-sm-block d-md-none" src={AboutSmall} loading="lazy" />
        <img
          className="d-sm-none d-md-block d-none"
          src={Aboutbg}
          loading="lazy"
        />
      </div>
      <div className="highlight-list-container">
        <h1 className="highlight-list-title">Latest Highlights</h1>

        {loading ? (
          <div className="loader">Loading highlights...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : highlights.length > 0 ? (
          <div className="highlight-cards">
            {highlights.map((highlight) => (
              <div
                key={highlight.id}
                className="highlight-card"
                onClick={() => openHighlight(highlight.slug)}
              >
                <img
                  src={highlight.featureImageUrl}
                  alt={highlight.title}
                  className="highlight-image"
                  loading="lazy"
                />
                <div className="highlight-details">
                  <h2 className="highlight-title">{highlight.title}</h2>
                  <p className="highlight-date">
                    {new Date(highlight.publishedDate).toLocaleDateString()}
                  </p>
                  <p className="highlight-content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getExcerpt(highlight.contentPreview),
                      }}
                    ></div>
                    <span
                      className="read-more-link"
                      onClick={(e) => {
                        e.stopPropagation();
                        openHighlight(highlight.slug);
                      }}
                    >
                      Read More
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-highlights-message">
            No highlights available at the moment.
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default HighlightList;
